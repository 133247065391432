<template>
  <div style="background-color: white;">
    <div class="print-only" style="margin-bottom: 50px; margin-top: 20px">
      <img src="/lg.jpg" width="100"/>
    </div>
    <h2>
      {{item.name}}
    </h2>
    <div class="mb-2">
      {{item.country}} | Implementation status: {{item.impactstatus.name}}
    </div>
    <div v-if="item.impactpolicy_id > 0 && !item.impactpolicy.overview">
      <div><h4 class="mt-5">Policy details</h4></div>
      <policy-initiative-details :item="item" />
    </div>
    <div class="mb-2" style="font-size: 1.2em; font-weight: 500">
      <new-lines :content="item.headline"></new-lines>
    </div>
    <div v-html="item.description"></div>
    <div>
      <div class="mt-5"><small>{{item.created_by}} created on {{moment(item.created_at).format('YYYY-MM-DD')}}</small></div>
      <div><small>{{item.updated_by}} last updated on {{moment(item.updated_at).format('YYYY-MM-DD')}}</small></div>
    </div>
    <div v-if="item.impactpolicy_id">
      <h4 class="mt-5">Policy</h4>
      <div style="font-size: 1.2em">{{item.impactpolicy.name}}</div>
    </div>
    <div v-if="item.impactexperts.length > 0">
      <h4 class="mt-5">Experts</h4>
      <div v-for="expert in item.impactexperts" :key="'expert'+expert.id" style="font-size: 1.2em">
        {{expert.name}}
      </div>
    </div>
    <div v-if="item.sectors.length > 0">
      <h4 class="mt-5">Sectors</h4>
      <div v-for="row in item.sectors" :key="'sector'+row.id">
        <div>
          {{ row.sector }}
          <span v-if="row.industrygroup"> | {{ row.industrygroup }}</span>
          <span v-if="row.industry"> | {{ row.industry }}</span>
          <span v-if="row.subindustry"> | {{ row.subindustry }}</span>
        </div>
      </div>
    </div>
    <div class="page-break" style="page-break-after: always;"></div>
  </div>
</template>

<script>
import PolicyInitiativeDetails from '@/components/PolicyInitiativeDetailsSimple'
import moment from 'moment'
import NewLines from '@/components/NewLines.vue'

export default {
  name: 'InitiativeFullDownload',
  props: ['item'],
  components: {
    NewLines,
    PolicyInitiativeDetails
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
  },
  data () {
    return {
      moment: moment
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
